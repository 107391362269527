export function useSearch(
  initialSearch: Ref<string>,
  articleSearch: Boolean = false,
  isProfessional: Boolean = false
) {
  const { apiClient } = useShopwareContext()
  const search = initialSearch

  const getCriteria = () => {
    if (articleSearch) {
      const defaultCriteria = {
        limit: 10,
        filter: [
          {
            type: 'multi',
            operator: 'OR',
            queries: [
              {
                field: 'productNumber',
                type: 'contains',
                value: search.value,
              },
            ],
          },
          {
            type: 'multi',
            operator: 'OR',
            queries: [
              {
                type: 'multi',
                operator: 'AND',
                queries: [
                  {
                    field: 'parentId',
                    type: 'equals',
                    value: null,
                  },
                  {
                    field: 'childCount',
                    type: 'equals',
                    value: 0,
                  },
                ],
              },
              {
                type: 'not',
                operator: 'AND',
                queries: [
                  {
                    field: 'parentId',
                    type: 'equals',
                    value: null,
                  },
                ],
              },
            ],
          },
        ],
        associations: {
          seoUrls: {},
        },
      }
      if (!isProfessional) {
        defaultCriteria.filter.push({
          type: 'multi',
          operator: 'OR',
          queries: [
            {
              type: 'equals',
              field: 'erpProductInfo.isB2cProduct',
              value: true,
            },
          ],
        })
      }
      return defaultCriteria
    } else
      return {
        limit: 10,
        filter: [
          {
            type: 'multi',
            operator: 'OR',
            queries: [
              {
                field: 'name',
                type: 'contains',
                value: search.value,
              },
              {
                field: 'productNumber',
                type: 'contains',
                value: search.value,
              },
            ],
          },
          {
            type: 'multi',
            operator: 'OR',
            queries: [
              {
                type: 'multi',
                operator: 'AND',
                queries: [
                  {
                    field: 'parentId',
                    type: 'equals',
                    value: null,
                  },
                  {
                    field: 'childCount',
                    type: 'equals',
                    value: 0,
                  },
                ],
              },
              {
                type: 'not',
                operator: 'AND',
                queries: [
                  {
                    field: 'parentId',
                    type: 'equals',
                    value: null,
                  },
                ],
              },
            ],
          },
        ],
        associations: {
          seoUrls: {},
          categories: {},
        },
      }
  }

  const fetch = async () => {
    const {
      data: { elements },
    } = await apiClient.invoke('readProductStream post /product', {
      body: { ...getCriteria() },
    })
    return elements
  }

  const {
    data: products,
    refresh,
    status,
  } = useAsyncData('product-search-' + search.value, async () => {
    if (!search.value) {
      return []
    }
    return await fetch()
  })

  return {
    search,
    products,
    status,
    refresh,
    getCriteria,
  }
}
